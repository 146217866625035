import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Box, Toolbar } from '@mui/material';
import { memo } from 'react';
import { NavSectionHorizontal } from '../../../components/nav-section';
import { HEADER } from '../../../config-global';
import navConfig from './config-navigation';
// ----------------------------------------------------------------------
function NavHorizontal() {
    return (_jsxs(AppBar, { component: "nav", color: "transparent", sx: {
            boxShadow: 0,
            top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        }, children: [_jsx(Toolbar, { sx: { backgroundColor: 'transparent  !important' }, children: _jsx(NavSectionHorizontal, { data: navConfig }) }), _jsx(Shadow, {})] }));
}
export default memo(NavHorizontal);
// ----------------------------------------------------------------------
function Shadow({ sx, ...other }) {
    return (_jsx(Box, { sx: {
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            width: 1,
            m: 'auto',
            borderRadius: '50%',
            position: 'absolute',
            boxShadow: (theme) => theme.customShadows.z8,
            ...sx,
        }, ...other }));
}
